import React from 'react'
import Img from '@/assets/404-png.webp'
import styles from './index.module.scss'

export default function ErrorPage() {
  return (
    <div className={styles.error}>
      <div className="content">
        <div className={styles.container}>
          <div className={styles.error_logo}>
            <img src={Img.src} alt="" />
          </div>
          <p className={styles.title}>
            Oops，request fail！We have a lot of pages but not this one.&nbsp;
            Or, try one of these, instead:
          </p>
          <ul className={styles.ul_container}>
            <li>
              <a href="/">Home</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
